<template>
  <div class="box-code">
    <div class="navs" style="background-color: #f5c319">
      <el-input
        style="display: inline-block; width: 200px"
        class="gullters"
        v-model="formInfo.title"
        :size="size"
        placeholder="请输入作品名称"
      />
      <!-- <el-button
        class="gullters"
        style="display: inline-block"
        @click="newFile"
        type="primary"
        :size="size"
      >
        新建
      </el-button> -->
      <el-button
        class="gullters"
        style="display: inline-block"
        @click="openWinEditor('save')"
        type="primary"
        :size="size"
      >
        保存
      </el-button>
      <el-button
        v-if="formInfo.id && formInfo.id !== taskInfo.sourceId"
        class="gullters"
        style="display: inline-block"
        @click="openWinEditor('other')"
        type="primary"
        :size="size"
      >
        另存为
      </el-button>
      <el-button
        v-if="buttons.works"
        type="primary"
        @click="showList"
        :size="size"
      >
        提交记录
      </el-button>
    </div>
    <iframe
      id="scratchjriframe"
      class="iframe-style"
      :src="iframeWinPaht"
      frameborder="0"
      ref="iframes"
      seamless
      allowfullscreen="true"
      sandbox="allow-downloads allow-scripts allow-modals allow-forms allow-top-navigation allow-same-origin allow-popups"
    />
    <editor
      @saveFile="saveFile"
      :formInfos="formInfo"
      :rules="rules"
      ref="editor"
      title="提交表单"
    />
    <work-list
      @showItem="showItem"
      :taskId="taskInfo.id"
      ref="list"
    ></work-list>
  </div>
</template>
<script>
import { getWorkInfo, editWorkInfo, saveProject } from "@/api/works/index";
import {
  getSrcTplInfo,
  saveCreation,
  uploadScratch,
  getInformation,
} from "@/apiteacher/teacher";
import { formatDate } from "@/utils/formatDate";
import { uploadFile, getFileText } from "@/api/works/file";
import Editor from "./components/editor.vue";
import WorkList from "./components/workList.vue";
export default {
  name: "ScratchJrEdit",
  components: {
    Editor,
    WorkList,
  },
  props: {
    taskInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    "taskInfo.id": {
      handler(val, old) {
        if (val != old) {
          setTimeout(() => {
            if (this.$route.query.id || this.taskInfo.sourceId) {
              this.iframeWin.postMessage(
                {
                  type: "initData",
                  data: { id: this.$route.query.id || this.taskInfo.sourceId },
                },
                "*"
              );
            } else {
              this.newFile();
            }
          }, 2000);
        }
      },
      deep: true,
      // immediate: true,
    },
  },
  data() {
    return {
      currentStrId: null,
      size: "small",
      loading: false, //编辑器
      buttons: {
        works: true, // 作品中心按钮
        file: false, // 文件操作按钮
        list: false, // 作品列表按钮
      },
      iframeWin: {},
      iframeWinPaht:
        process.env.VUE_APP_PREVIEW_SCRATCHJR +
        "editor.html?pmd5=" +
        uuid() +
        "&mode=edit",
      scratchJrLoading: false,
      activeIndex: "1",
      saveAs: false,
      saveType: "save",
      formInfo: {
        title: "", //	是	String	作品名称
        description: "", //		否	String	简介
        cover: "", //		否	String	封面
        label: "", //		否	String	标签 (‘游戏’)
        category: "ScrachJr", //		是	String	类别(‘图形化编程’,’Python’,’Microbit’)
        source: "创作", //		是	String	来源(‘活动’,’创作’,’课程’)
        content: "", //		否	String	内容
        type: "文件", //		是	String	类型(‘文件’,’代码’）,类型为“文件”时，将文件路径置于sourceCodeFile,类型为“代码”时，将代码内容置于content
        sourceCodeFile: "", //		否	String	源代码文件的路径
        videoFile: "", //		否	String	作品视频简介
      },
      rules: {
        title: [
          { required: true, message: "作品名称不可为空", trigger: "blur" },
        ],
        description: [
          { required: false, message: "作品描述", trigger: "blur" },
        ],
      },
      PythonList: [],
      finishFlag: true,
    };
  },
  mounted() {
    this.iframeWin = document.getElementById("scratchjriframe").contentWindow;
    window.addEventListener("message", this.handleMessage, true);
    let _this = this;
    timer();
    function timer() {
      setTimeout(() => {
        console.log(2323);
        if (_this.finishFlag) {
          timer();
          return;
        }
        if (_this.$route.query.id || _this.taskInfo.sourceId) {
          _this.iframeWin.postMessage(
            {
              type: "initData",
              data: { id: _this.$route.query.id || _this.taskInfo.sourceId },
            },
            "*"
          );
        } else {
          _this.newFile();
        }
      }, 2000);
    }
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage, true);
  },
  methods: {
    //showList
    showList() {
      this.$refs.list.open();
      this.$refs.list.getWorksList();
    },
    //  项目跳入
    ////task/taskScratchInfo
    showItem(item) {
      this.getWorkInfo(item.id);
    },
    getTaskInfo(id) {
      this.loading = true;
      getWorkInfo({
        id,
      })
        .then((res) => {
          this.formInfo = res.body;
          if (res.body.file) {
            this.iframeWin.postMessage(
              {
                type: "loadProjectSjr",
                data: { url: res.body.file },
              },
              "*"
            );
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    inputChange(e) {
      this.formInfo.description = null;
      this.formInfo.id = null;
      if (e.target.files.length > 0) {
        this.iframeWin.postMessage(
          { type: "openLoacalProject", data: { file: e.target.files[0] } },
          "*"
        );
      }
    },
    openWinEditor(str) {
      this.saveType = str;
      this.$refs.editor.open();
    },
    // 初次获取详情信息
    getWorkInfo(id) {
      this.loading = true;
      getWorkInfo({
        id,
      })
        .then((res) => {
          // console.log(res);
          this.$refs.list.close();
          this.formInfo = res.body;
          if (res.body.sourceCodeFile) {
            this.iframeWin.postMessage(
              {
                type: "loadProjectSjr",
                data: { url: res.body.sourceCodeFile },
              },
              "*"
            );
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleMessage(e) {
      switch (e.data.type) {
        case "onSaveCloud":
          this.saveFileList(e.data.data, (coverUrl, fileUrl) => {
            this.formInfo.sourceCodeFile = fileUrl;
            this.formInfo.cover = coverUrl;
            this.$nextTick(() => {
              if (
                this.formInfo.id &&
                this.formInfo.id != this.taskInfo.sourceId
              ) {
                this.editWorkInfo(this.formInfo);
              } else {
                this.saveProject(this.formInfo);
              }
            });
          });
          break;
        case "onSaveOther":
          this.saveFileList(e.data.data, (coverUrl, fileUrl) => {
            this.formInfo.sourceCodeFile = fileUrl;
            this.formInfo.cover = coverUrl;
            this.$nextTick(() => {
              this.saveProject({ ...this.formInfo });
            });
          });
          break;
        case "newProjectFinish":
          if (this.$route.query.id || this.taskInfo.sourceId) {
            setTimeout(() => {
              this.getWorkInfo(this.$route.query.id || this.taskInfo.sourceId);
            }, 1000);
          } else {
            this.loading = false;
          }
          break;
        case "initScratchJrProject":
          this.finishFlag = false;
        case "loadProjectFinish":
          // console.log("loadProjectFinish");
          this.loading = false;
          break;
        default:
          break;
      }
    },
    //监听文件操作
    handleCommands(str) {
      this.$nextTick(() => {
        switch (str) {
          case "a":
            this.newFile();
            break;
          case "b":
            this.$refs.newfileinput.click();
            break;
          case "c":
            this.exportFile();
            break;
          case "d":
            this.openWinEditor("other");
            break;
          default:
            break;
        }
      });
    },
    async saveFileList(props, cb) {
      let coverUrl = await this.uploadJrFile(props.cover);
      let fileUrl = await this.uploadJrFile(props.file);
      cb(coverUrl.body.fileUrl, fileUrl.body.fileUrl);
    },
    uploadJrFile(file) {
      let params = new FormData();
      params.append("file", file);
      return uploadFile(params);
    },
    // 保存代码
    saveFile(form) {
      this.loading = true;
      this.formInfo = {
        ...this.formInfo,
        title: form.title,
        description: form.description,
        source: this.$route.query.source || this.formInfo.source,
      };
      if (this.saveType === "save") {
        this.onSaveCloud();
      } else if (this.saveType === "other") {
        this.onSaveOther();
      }
    },
    editWorkInfo(props) {
      editWorkInfo({
        // courseId: this.$route.query.ci,
        // taskId: this.taskInfo.id,
        id: props.id,
        title: props.title,
        description: props.description,
        category: "ScratchJr",
        source: "课程",
        type: "文件",
        sourceCodeFile: props.sourceCodeFile,
        cover: props.cover,
      })
        .then((res) => {
          this.loading = false;
          this.$message.success("提交成功");
          this.$refs.editor.close();
        })
        .catch((err) => {
          this.loading = false;
          this.$message.warning("提交失败");
        });
    },
    saveProject(props) {
      saveCreation({
        courseId: this.$route.query.ci,
        taskId: this.taskInfo.id,
        title: props.title,
        description: props.description,
        category: "ScratchJr",
        source: "课程",
        type: "文件",
        sourceCodeFile: props.sourceCodeFile,
        cover: props.cover,
      })
        .then((res) => {
          this.$message.success("提交成功");
          this.$refs.editor.close();
          this.loading = false;
          this.formInfo.id = res.body;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.warning("提交失败");
        });
    },
    //新建作品
    newFile() {
      this.formInfo.title = null;
      this.formInfo.id = null;
      this.formInfo.description = null;
      this.iframeWin.postMessage({ type: "newProject" }, "*");
    },
    //下载作品
    exportFile() {
      this.iframeWin.postMessage({ type: "saveLoacalProject" }, "*");
    },
    // 保存
    onSaveCloud() {
      this.iframeWin.postMessage({ type: "onSaveCloud" }, "*");
    },
    //另存为
    onSaveOther() {
      this.loading = true;
      this.iframeWin.postMessage({ type: "onSaveOther" }, "*");
    },
  },
};
</script>
<style scoped lang='scss'>
.iframes {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
<style>
.el-loading-mask {
  z-index: 9999;
}
.box-code {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.iframe-style {
  width: 100%;
  height: 100%;
  position: relative;
}
.iframe-style::-webkit-scrollbar {
  display: none;
}

.nav {
  height: 40px;
  padding-top: 4px;
  padding-left: 10px;
  /* background-color: #121212; */
  /* background-image: linear-gradient(to right, #ffe02a, #f5c319, #e6a23c); */
}

.navs {
  height: 40px;
  padding-top: 4px;
  padding-left: 40px;
}

.gullters {
  margin-left: 10px;
}
/* 
.el-dropdown-menu{
  display: block !important;
} */
</style>